import { LgProvider, LgProviders } from "@logex/framework/types";
import { Optional, Provider, SkipSelf } from "@angular/core";
import { LG_FILTERSET_FORROOT_GUARD, lgFiltersetForRootGuardFn } from "../lg-filterset.module";
import { ComboFilterPreprocessor } from "../preprocessors/combo-filter-preprocessor";
import { ComboFilter2Preprocessor } from "../preprocessors/combo-filter2-preprocessor";
import { DropdownFilterPreprocessor } from "../preprocessors/dropdown-filter-preprocessor";
import { TextInputFilterRendererFactory } from "../renderers/text-input-filter-renderer";
import { IFilterPreprocessor, LG_FILTERSET_PREPROCESSORS } from "../filter-preprocessor";
import { IFilterRendererFactory, LG_FILTERSET_RENDERER_FACTORIES } from "../filter-renderer";
import { LgFilterSetService } from "../lg-filterset.service";
import { LgPreprocessingFilterSetService } from "../lg-preprocessing-filterset.service";
import {
    CheckboxFilterRendererFactory,
    ComboFilterRenderer2Factory,
    ComboFilterRendererFactory,
    DateFilterRendererFactory,
    DropdownFilterRendererFactory,
    InputRangeFilterRendererFactory,
    RangeFilterRendererFactory,
    SelectableComboFilterRenderer2Factory,
    SelectedItemFilterRendererFactory,
    SwitchFilterRendererFactory,
    TristateFilterRendererFactory,
    TristateSliderFilterRendererFactory
} from "../renderers";

export interface LgFiltersetConfig {
    /**
     * Provides custom filter preprocessors (token `LG_FILTERSET_PREPROCESSORS`).
     * Optional. Default preprocessors used in `LgFiltersetModule` are:
     *  - ComboFilterPreprocessor
     *  - ComboFilter2Preprocessor
     *  - DropdownFilterPreprocessor
     */
    preprocessors?: Array<LgProvider<IFilterPreprocessor>>;
    /**
     * Provides custom filter renderer factories (token `LG_FILTERSET_RENDERER_FACTORIES`).
     * Optional. Default renderer factories used in `LgFiltersetModule` are:
     *  - CheckboxFilterRendererFactory
     *  - ComboFilterRendererFactory
     *  - ComboFilter2RendererFactory
     *  - DateFilterRendererFactory
     *  - DropdownFilterRendererFactory
     *  - InputRangeFilterRendererFactory
     *  - RangeFilterRendererFactory
     *  - SelectableComboFilterRenderer2Factory
     *  - SelectedItemFilterRendererFactory
     *  - SwitchFilterRendererFactory
     *  - TristateFilterRendererFactory
     *  - TristateSliderFilterRendererFactory
     */
    rendererFactories?: Array<LgProvider<IFilterRendererFactory>>;
}

/**
 * Helper utility function to configure and provide `LgFiltersetModule` providers
 */
export const lgFiltersetProviders: LgProviders<LgFiltersetConfig> = (
    config?: LgFiltersetConfig
): Provider[] => [
    ...provideForRootGuard(),
    ...providePreprocessors(config),
    ...provideRendererFactories(config)
];

const provideForRootGuard = (): Provider[] => {
    return [
        {
            provide: LG_FILTERSET_FORROOT_GUARD,
            useFactory: lgFiltersetForRootGuardFn,
            deps: [[LgFilterSetService, new Optional(), new SkipSelf()]]
        },
        LgFilterSetService,
        LgPreprocessingFilterSetService
    ];
};

const providePreprocessors = (config?: Pick<LgFiltersetConfig, "preprocessors">): Provider[] => {
    const providers: Provider[] = [
        {
            provide: LG_FILTERSET_PREPROCESSORS,
            useClass: ComboFilterPreprocessor,
            multi: true
        },
        {
            provide: LG_FILTERSET_PREPROCESSORS,
            useClass: ComboFilter2Preprocessor,
            multi: true
        },
        {
            provide: LG_FILTERSET_PREPROCESSORS,
            useClass: DropdownFilterPreprocessor,
            multi: true
        }
    ];
    if (config?.preprocessors) {
        providers.push(
            config.preprocessors.map(preprocessor => ({
                provide: LG_FILTERSET_PREPROCESSORS,
                ...preprocessor,
                multi: true
            }))
        );
    }
    return providers;
};

const provideRendererFactories = (
    config?: Pick<LgFiltersetConfig, "rendererFactories">
): Provider[] => {
    const providers: Provider[] = [
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: CheckboxFilterRendererFactory,
            multi: true
        },
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: ComboFilterRendererFactory,
            multi: true
        },
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: ComboFilterRenderer2Factory,
            multi: true
        },
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: DateFilterRendererFactory,
            multi: true
        },
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: DropdownFilterRendererFactory,
            multi: true
        },
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: InputRangeFilterRendererFactory,
            multi: true
        },
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: RangeFilterRendererFactory,
            multi: true
        },
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: SelectableComboFilterRenderer2Factory,
            multi: true
        },
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: SelectedItemFilterRendererFactory,
            multi: true
        },
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: SwitchFilterRendererFactory,
            multi: true
        },
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: TextInputFilterRendererFactory,
            multi: true
        },
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: TristateFilterRendererFactory,
            multi: true
        },
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: TristateSliderFilterRendererFactory,
            multi: true
        }
    ];
    if (config?.rendererFactories) {
        providers.push(
            config.rendererFactories.map(factory => ({
                provide: LG_FILTERSET_RENDERER_FACTORIES,
                ...factory,
                multi: true
            }))
        );
    }
    return providers;
};
