import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    inject,
    Input,
    OnInit,
    TemplateRef,
    ViewEncapsulation
} from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    IAppVersion,
    IUser,
    IVersion,
    LG_APP_CONFIGURATION,
    LG_APP_VERSION,
    LG_USER_INFO,
    LG_USERFLOW_SERVICE,
    VersionService
} from "@logex/framework/lg-application";

import { LgReleaseNotesDialogFactory } from "../../lg-release-notes";
import { ISidebar } from "../sidebar-context";
import { DOCUMENT } from "@angular/common";
import { LgChatController } from "@logex/framework/lg-chat";

export interface SidebarHelpContext {
    $implicit: IVersion | null;
    appVersion: IAppVersion;
    user: IUser;
    internal: boolean;
}

export interface SidebarHelpStandardLinks {
    manual?: string;
    FAQ?: string;
    dataSpecification?: string;
    webinar?: string;
    serviceDesk?: string;
}

export interface SidebarHelpLinkItem {
    name: string;
    url: string;
    icon?: string;
}

enum StandardSidebarLinksIcon {
    manual = "icon-manual",
    FAQ = "icon-faq",
    dataSpecification = "icon-regel",
    webinar = "icon-webinar",
    serviceDesk = "icon-helpcenter"
}

enum StandardSidebarLinksPosition {
    manual = 1,
    FAQ = 2,
    dataSpecification = 3,
    webinar = 4,
    serviceDesk = 5
}

// ----------------------------------------------------------------------------------
//
@Component({
    selector: "lg-sidebar-help",
    templateUrl: "./lg-sidebar-help.component.html",
    host: {
        class: "lg-sidebar-help flexcol"
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [useTranslationNamespace("FW._Sidebar._Help")]
})
export class LgSidebarHelpComponent implements OnInit {
    appConfig = inject(LG_APP_CONFIGURATION);
    appVersion = inject(LG_APP_VERSION);
    userflowService = inject(LG_USERFLOW_SERVICE);
    userInfo = inject(LG_USER_INFO);
    private _releaseNotes = inject(LgReleaseNotesDialogFactory);
    private _translationService = inject(LgTranslateService);
    private _versionService = inject<VersionService<IVersion>>(VersionService<IVersion>);
    private _document = inject(DOCUMENT);
    private _chatController = inject(LgChatController);

    /**
     * Specifies the link to the support page of desired product.
     * Defaults to "https://support.logex.nl/".
     */
    @Input() supportLink: string | null = "https://support.logex.nl/";
    /**
     * Specifies standard list of links. If null, the item is not shown in the list.
     * There are four standard links.
     *
     * @type { SidebarHelpStandardLinks }
     * @property { string } manual - Specifies the url to manual.
     * @property { string } FAQ - Specifies the url to frequently asked questions.
     * @property { string } dataSpecification - Specifies the url to data specifications.
     * @property { string } webinar - Specifies the url to webinar.
     */
    @Input() standardLinks: SidebarHelpStandardLinks | null = null;
    /**
     * Specifies complementary items to the standard help links.
     * These links are shown in the order passed to the component.
     * Defaults to [].
     *
     * @type { Array<SidebarHelpLinkItem> }
     * @property { string } name - Specifies localized name to show in the sidebar.
     * @property { string } url - Specifies the url to the desired page.
     * @property { string } icon - Optional parameter. Specifies the icon shown before the name.
     */
    @Input() additionalItems: SidebarHelpLinkItem[] = [];
    /**
     * Specifies whether release notes are clickable or not
     */
    @Input() versionClickable = true;
    /**
     * If specified, the sidebar is closed on checklist click
     */
    @Input() sidebar: ISidebar | undefined;

    @ContentChild("extension") _extensionTemplateRef!: TemplateRef<SidebarHelpContext>;

    _internal = false;
    _context!: SidebarHelpContext;
    _version?: IVersion;
    _standardLinks: SidebarHelpLinkItem[] = [];

    readonly isChecklistReady$!: Observable<boolean>;

    constructor() {
        this._internal = this.userInfo.hasPermission("internal");

        this._context = {
            $implicit: null,
            appVersion: this.appVersion,
            user: this.userInfo,
            internal: this._internal
        };

        this._versionService.version.subscribe(version => {
            this._context.$implicit = version;
            this._version = version;
        });

        this.isChecklistReady$ = this.userflowService.ready$.pipe(
            map(() => !!this.appConfig.userflowContentId)
        );
    }

    ngOnInit(): void {
        if (this.standardLinks == null) return;
        this._standardLinks = this._getMappedStandardLinks();
    }

    get _generalSupportLinks(): SidebarHelpLinkItem[] {
        return [...(this._standardLinks ?? []), ...this.additionalItems];
    }

    get _releaseNotesLabel(): string {
        return this.versionClickable ? ".ReleaseNotes" : ".Version";
    }

    _hasChat(): boolean {
        const chats = this._document.getElementsByTagName("lg-chat");
        let chatsLength = chats.length;
        for (let i = 0; i < chats.length; i++) {
            if (chats[i].attributes.getNamedItem("disabled")?.value === "true") {
                chatsLength--;
            }
        }
        return chatsLength > 0;
    }

    _hasReleaseNotes(): boolean {
        return !!this.appVersion.version;
    }

    private _getMappedStandardLinks(): SidebarHelpLinkItem[] {
        return Object.keys(this.standardLinks ?? {})
            .map(x => {
                const localizationId = x.charAt(0).toUpperCase() + x.slice(1);
                return <SidebarHelpLinkItem>{
                    url: this.standardLinks![x as keyof SidebarHelpStandardLinks],
                    name: this._translationService.translate("." + localizationId),
                    icon: StandardSidebarLinksIcon[x as keyof typeof StandardSidebarLinksIcon],
                    sort: StandardSidebarLinksPosition[
                        x as keyof typeof StandardSidebarLinksPosition
                    ]
                };
            })
            .sort((x, y) => (x as any).sort - (y as any).sort);
    }

    _showReleaseNotes(): void {
        if (!this.versionClickable) return;
        this._releaseNotes.show();
    }

    _openChat(): void {
        this._chatController.openChat();
    }

    async showChecklist($event: Event): Promise<void> {
        $event.preventDefault();
        $event.stopPropagation();
        this.userflowService.showContent(this.appConfig.userflowContentId!);
        this.sidebar?.dismissPanel();
    }
}
