import ldIsFunction from "lodash-es/isFunction";
import ldIsString from "lodash-es/isString";
import ldIsNumber from "lodash-es/isNumber";

import { Overlay, ScrollDispatcher } from "@angular/cdk/overlay";
import { ComponentPortal, Portal } from "@angular/cdk/portal";
import { ElementRef, Injectable, Renderer2, RendererFactory2, inject } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { IOverlayResultApi, LgOverlayService } from "../lg-overlay/lg-overlay.service";
import { ExtendedConnectedPosition, LgTooltipHolderComponent } from "./lg-tooltip-holder.component";
import {
    TooltipApi,
    ITooltipOptions,
    TooltipPosition,
    ITooltipShowOptions
} from "./lg-tooltip.types";

const defaultOptions: ITooltipOptions = {
    position: "bottom-left", // Defines position of the tooltip. Can be specified on show. The position is formed of 2 directions: going from the item, and then aligning the window, so f.ex "bottom-left"
    forcePosition: false,
    stay: false, // defines, if the tooltip should stay until clicked away (or click on close button ). Can be specified on show
    hideOnScroll: false, // defines, if the tooltip should hide after scrolling (It's above waitForMouse). Is ignored if stay is true. Defaults to false
    closeButton: false, // defines if the close buttton should be shown. Available only when combined with stay. Can be specified on show
    delayShow: 250, // defines the delay between the hover on element, and the tooltip showing. Specify on create
    delayHide: 250, // defines the delay between hovering away and the tooltip hiding. Specify on create
    tooltipClass: null, // defines the class of the tooltip. Defaults to "lg-tooltip". Can be specified on show
    waitForMouse: false, // if true, the tooltip won't autohide until the user hovered over it. Only when stay is not specified. Can be given on show
    preShow: null, // if specified, call the callback before the tooltip is shown. If the function returns false, the tooltip won't show. This can be specified on show, though probably makes sense only on creation
    postShow: null, // if specified, call the callback after the tooltip is shown (before animation is done though). Get the tooltip's holder as argument
    preHide: null, // if specified, call the callback before the tooltip is hidden. If the function returns false, the tooltip won't hide. Can be specified on show
    postHide: null,
    content: null, // if specified, call the function to return the content of the tooltip. The function can either return a string, or Portal. Can be specified on show
    //                     alternatively, set this directly to what the return of the function can be (so a string, or object)
    animationEnabled: false,
    target: null, // specify the element, to which should the tooltip be attached. This can be also be a function returning the target.
    offset: null, // specify the offset of the whole popup from the regular arrow position (also moves the arrow towards the popup center)
    arrowOffset: null, // specify the offset of the arrow from the regular position (moves it towards center). If offset is specified too, both are applied to the arrow
    overlayClass: ""
};

// ---------------------------------------------------------------------------------------------
//  Implementation
// ---------------------------------------------------------------------------------------------
@Injectable({ providedIn: "root" })
export class LgTooltipService {
    private _overlay = inject(Overlay);
    private _overlayService = inject(LgOverlayService);
    private _rendererFactory = inject(RendererFactory2);
    private _scrollDispatcher = inject(ScrollDispatcher);

    private _renderer: Renderer2;

    public constructor() {
        this._renderer = this._rendererFactory.createRenderer(null, null);
    }

    /**
     * Create a new tooltip, connected to specified scope. The tooltip is destroyed when the scope is.
     *
     * @param globalOptions configures the tooltip. Most of the options can be override on show() time.
     *
     * @return the api used to show/hide the tooltip
     */
    public create(globalOptions: ITooltipOptions): TooltipApi {
        globalOptions = { ...defaultOptions, ...globalOptions };

        let visible = false;
        let destroyed = false;
        let holderEntered = false;
        let showOptions: ITooltipOptions | null = null;
        let timer: number | null = null;
        let overlay: IOverlayResultApi | null;
        let tooltipInstance: LgTooltipHolderComponent | null;
        const afterVisibilityChanged$ = new Subject<boolean>();
        let currentTarget: HTMLElement | null = null;
        let currentTargetActiveClass: string | undefined = undefined;

        // ---------------------------------------------------------------------------------------------
        function getConnectionPoint(
            position: TooltipPosition,
            offset: number,
            arrowOffset: number,
            distance: number
        ): ExtendedConnectedPosition {
            switch (position) {
                case "bottom-left":
                default:
                    return {
                        originX: "center",
                        originY: "bottom",
                        overlayX: "end",
                        overlayY: "top",
                        offsetX: offset,
                        offsetY: distance,
                        className: "bottom-left",
                        arrowLeft: null,
                        arrowRight: arrowOffset,
                        arrowTop: 0,
                        arrowBottom: null
                    };
                case "bottom-right":
                    return {
                        originX: "center",
                        originY: "bottom",
                        overlayX: "start",
                        overlayY: "top",
                        offsetX: -offset,
                        offsetY: distance,
                        className: "bottom-right",
                        arrowLeft: arrowOffset,
                        arrowRight: null,
                        arrowTop: 0,
                        arrowBottom: null
                    };
                case "top-left":
                    return {
                        originX: "center",
                        originY: "top",
                        overlayX: "end",
                        overlayY: "bottom",
                        offsetX: offset,
                        offsetY: -distance,
                        className: "top-left",
                        arrowLeft: null,
                        arrowRight: arrowOffset,
                        arrowTop: null,
                        arrowBottom: 0
                    };
                case "top-right":
                    return {
                        originX: "center",
                        originY: "top",
                        overlayX: "start",
                        overlayY: "bottom",
                        offsetX: -offset,
                        offsetY: -distance,
                        className: "top-right",
                        arrowLeft: arrowOffset,
                        arrowRight: null,
                        arrowTop: null,
                        arrowBottom: 0
                    };
                case "left-bottom":
                    return {
                        originX: "start",
                        originY: "center",
                        overlayX: "end",
                        overlayY: "top",
                        offsetX: -distance,
                        offsetY: -offset,
                        className: "left-bottom",
                        arrowLeft: null,
                        arrowRight: 0,
                        arrowTop: arrowOffset,
                        arrowBottom: null
                    };
                case "left-top":
                    return {
                        originX: "start",
                        originY: "center",
                        overlayX: "end",
                        overlayY: "bottom",
                        offsetX: -distance,
                        offsetY: offset,
                        className: "left-top",
                        arrowLeft: null,
                        arrowRight: 0,
                        arrowTop: null,
                        arrowBottom: arrowOffset
                    };
                case "right-bottom":
                    return {
                        originX: "end",
                        originY: "center",
                        overlayX: "start",
                        overlayY: "top",
                        offsetX: distance,
                        offsetY: -offset,
                        className: "right-bottom",
                        arrowLeft: 0,
                        arrowRight: null,
                        arrowTop: arrowOffset,
                        arrowBottom: null
                    };
                case "right-top":
                    return {
                        originX: "end",
                        originY: "center",
                        overlayX: "start",
                        overlayY: "bottom",
                        offsetX: distance,
                        offsetY: offset,
                        className: "right-top",
                        arrowLeft: 0,
                        arrowRight: null,
                        arrowTop: null,
                        arrowBottom: arrowOffset
                    };
            }
        }

        // ---------------------------------------------------------------------------------------------
        function getAlternativeOrientations(position: TooltipPosition): TooltipPosition[] {
            switch (position) {
                case "bottom-left":
                default:
                    return ["bottom-right", "top-left", "top-right"];
                case "bottom-right":
                    return ["bottom-left", "top-right", "top-left"];
                case "top-left":
                    return ["top-right", "bottom-left", "bottom-right"];
                case "top-right":
                    return ["top-left", "bottom-right", "bottom-left"];
                case "left-bottom":
                    return ["left-top", "right-bottom", "right-top"];
                case "left-top":
                    return ["left-bottom", "right-top", "right-bottom"];
                case "right-bottom":
                    return ["right-top", "left-bottom", "left-top"];
                case "right-top":
                    return ["right-bottom", "left-top", "left-bottom"];
            }
        }

        // ---------------------------------------------------------------------------------------------
        function determineAutoPosition(
            target: ElementRef,
            position: TooltipPosition
        ): TooltipPosition {
            if (position === "auto-left-right" || position === "auto-top-bottom") {
                const offs = target.nativeElement.getBoundingClientRect();
                const elementWidth = target.nativeElement.offsetWidth;
                const elementHeight = target.nativeElement.offsetHeight;
                let quarter = 0;
                const windowWidth = window.innerWidth;
                const windowHeight = window.innerHeight;

                // left-right
                if (offs.left + elementWidth / 2 >= windowWidth / 2) {
                    quarter += 1;
                }
                // top-bottom
                if (offs.top + elementHeight / 2 >= windowHeight / 2) {
                    quarter += 2;
                }

                // note: we could do string merging here instead, but using mapping is more extensible
                if (position === "auto-left-right") {
                    const mapping: TooltipPosition[] = [
                        "right-bottom",
                        "left-bottom",
                        "right-top",
                        "left-top"
                    ];
                    position = mapping[quarter];
                } else {
                    const mapping: TooltipPosition[] = [
                        "bottom-right",
                        "bottom-left",
                        "top-right",
                        "top-left"
                    ];
                    position = mapping[quarter];
                }
            }
            return position;
        }

        // ---------------------------------------------------------------------------------------------
        //  Create the actual api
        // ---------------------------------------------------------------------------------------------
        const api: TooltipApi = {
            visible: false,

            // ---------------------------------------------------------------------------------------------
            options: <any>((options?: ITooltipOptions): TooltipApi | ITooltipOptions => {
                if (options === undefined) return globalOptions;
                globalOptions = { ...globalOptions, ...options };
                return api;
            }),

            // ---------------------------------------------------------------------------------------------
            show: (options?: ITooltipShowOptions) => {
                if (visible || destroyed) return;

                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }

                showOptions = { ...globalOptions, ...options };

                if (showOptions.preShow && showOptions.preShow.call(api, api) === false) return;

                let innerText: string | undefined;
                let innerPortal: Portal<any> | null | undefined;

                let content: string | number | Portal<any> | null | undefined;
                if (ldIsFunction(showOptions.content)) {
                    content = showOptions.content(api);
                } else {
                    content = showOptions.content;
                }

                if (ldIsString(content) || ldIsNumber(content)) {
                    innerText = content.toString();
                    innerPortal = undefined;
                } else {
                    innerText = undefined;
                    innerPortal = content;
                }

                if (!innerPortal && !innerText) return;

                const targetElement = ldIsFunction(showOptions.target)
                    ? showOptions.target(api)
                    : showOptions.target;

                const elementRef: ElementRef =
                    targetElement instanceof ElementRef
                        ? targetElement
                        : new ElementRef(targetElement);

                currentTarget = elementRef.nativeElement;
                currentTargetActiveClass = showOptions.targetActiveClass;

                if (currentTargetActiveClass) {
                    this._renderer.addClass(currentTarget, currentTargetActiveClass);
                }

                holderEntered = false;
                // the default position is bottom-left
                const finalPosition = determineAutoPosition(
                    elementRef,
                    showOptions.position ?? "bottom-left"
                );

                const positions = globalOptions.forcePosition
                    ? [finalPosition]
                    : [finalPosition, ...getAlternativeOrientations(finalPosition)];

                const strategy = this._overlay
                    .position()
                    .flexibleConnectedTo(elementRef)
                    .withFlexibleDimensions(false)
                    .withPush(false)
                    .withViewportMargin(0)
                    .withPositions(
                        positions.map(position =>
                            getConnectionPoint(
                                position,
                                globalOptions.offset || 0,
                                globalOptions.arrowOffset || 0,
                                globalOptions.distance || 0
                            )
                        )
                    );

                strategy.withScrollableContainers(
                    this._scrollDispatcher.getAncestorScrollContainers(elementRef)
                );

                // TODO: finalize the trap
                const useBackground = showOptions.stay || showOptions.waitForMouse;
                overlay = this._overlayService.show({
                    class: showOptions.overlayClass,
                    onClick: api.hide,
                    hasBackdrop: useBackground,
                    trapFocus: useBackground && showOptions.trapFocus,
                    focusPostHide: showOptions.focusPostHide ?? "ignore",
                    sourceElement: useBackground && showOptions.trapFocus ? elementRef : undefined,
                    positionStrategy: strategy,
                    scrollStrategy:
                        showOptions.hideOnScroll && !showOptions.stay
                            ? this._overlay.scrollStrategies.close()
                            : this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 })
                });

                const portal = new ComponentPortal(LgTooltipHolderComponent);
                tooltipInstance = overlay.overlayRef.attach(portal).instance;

                if (showOptions.backdropClickCallback) {
                    overlay.overlayRef
                        .backdropClick()
                        .subscribe(x => showOptions!.backdropClickCallback!(x));
                }

                // just make the whole options object a property?
                tooltipInstance.tooltipClass = showOptions.tooltipClass;
                tooltipInstance.message = innerText;
                tooltipInstance.portal = innerPortal;
                tooltipInstance.animationEnabled = showOptions.animationEnabled;
                tooltipInstance.hasClose = showOptions.closeButton && showOptions.stay;
                tooltipInstance.ensureVisibility = showOptions.ensureVisibility;

                const hidden: Subject<void> = new Subject();

                strategy.positionChanges.pipe(takeUntil(hidden)).subscribe(change => {
                    if (tooltipInstance)
                        tooltipInstance.setPosition(
                            change.connectionPair as ExtendedConnectedPosition
                        );
                });

                tooltipInstance.requestClose.pipe(takeUntil(hidden)).subscribe(api.hide);
                tooltipInstance.requestReposition.pipe(takeUntil(hidden)).subscribe(() => {
                    strategy.apply();
                });

                tooltipInstance.hover
                    .pipe(takeUntil(hidden), takeUntil(tooltipInstance.beforeHidden()))
                    .subscribe(over => {
                        if (over) {
                            api.scheduleShow();
                        } else {
                            api.scheduleHide();
                        }
                    });

                let originalOverlay: IOverlayResultApi | null = overlay;
                function detach(): void {
                    if (originalOverlay) {
                        const store = originalOverlay;
                        if (overlay === originalOverlay) overlay = null;
                        originalOverlay = null;
                        hidden.next();
                        hidden.complete();
                        if (store.overlayRef.hasAttached()) {
                            store.overlayRef.detach();
                        }
                        store.hide();
                    }
                }

                tooltipInstance.afterHidden().pipe(takeUntil(hidden)).subscribe(detach);

                overlay.overlayRef.detachments().pipe(takeUntil(hidden)).subscribe(detach);

                // TODO: modify so that it's called when the tooltip is really visible?
                if (showOptions.postShow) {
                    showOptions.postShow.call(api, api);
                }

                visible = true;
                api.visible = true;
                afterVisibilityChanged$.next(true);
            },

            // ---------------------------------------------------------------------------------------------
            hide: (immediately?: boolean) => {
                if (!visible) return;
                if (showOptions?.preHide && showOptions.preHide.call(api) === false) return;

                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }

                if (tooltipInstance) tooltipInstance.hide(immediately);

                if (currentTargetActiveClass) {
                    this._renderer.removeClass(currentTarget, currentTargetActiveClass);
                    currentTargetActiveClass = undefined;
                }

                currentTarget = null;
                tooltipInstance = null;
                visible = false;
                api.visible = false;

                if (showOptions && showOptions.postHide) showOptions.postHide();
                afterVisibilityChanged$.next(false);
            },

            // ---------------------------------------------------------------------------------------------
            hideShow: (options?: ITooltipShowOptions) => {
                if (!visible) {
                    api.show(options);
                    return;
                }

                if (showOptions && showOptions.preHide && showOptions.preHide.call(api) === false)
                    return;

                if (tooltipInstance) tooltipInstance.hide();

                if (currentTargetActiveClass) {
                    this._renderer.removeClass(currentTarget, currentTargetActiveClass);
                    currentTargetActiveClass = undefined;
                }

                currentTarget = null;
                tooltipInstance = null;
                visible = false;
                api.visible = false;

                api.show(options);
            },

            // ---------------------------------------------------------------------------------------------
            scheduleShow: () => {
                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }

                if (!visible) {
                    timer = window.setTimeout(api.show, globalOptions.delayShow);
                } else {
                    holderEntered = true;
                }
            },

            // ---------------------------------------------------------------------------------------------
            scheduleHide: () => {
                if (showOptions && showOptions.stay) return;
                if (overlay && overlay.overlayRef.backdropElement && !holderEntered) return;

                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }
                if (visible) {
                    timer = window.setTimeout(api.hide, globalOptions.delayHide);
                }
            },

            // ---------------------------------------------------------------------------------------------
            getPortalReference: () => {
                if (!tooltipInstance) return null;
                return tooltipInstance.portalReference;
            },

            // ---------------------------------------------------------------------------------------------
            afterVisibilityChanged: () => afterVisibilityChanged$.asObservable(),

            // ---------------------------------------------------------------------------------------------
            destroy: () => {
                (api as any).hide(true);

                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }

                // hide immediately
                overlay?.overlayRef?.detach();
                overlay?.hide();
                overlay = null;

                destroyed = true;

                api.show = api.scheduleShow = api.hideShow = () => undefined;
                afterVisibilityChanged$.complete();
            },

            // ---------------------------------------------------------------------------------------------
            reposition: (): void => {
                if (overlay && overlay.overlayRef) {
                    overlay.overlayRef.updatePosition();
                }
            },

            // ---------------------------------------------------------------------------------------------
            setPosition: (position: TooltipPosition): void => {
                globalOptions.position = position;

                if (tooltipInstance) {
                    tooltipInstance.setPosition(
                        getConnectionPoint(
                            position,
                            globalOptions.offset || 0,
                            globalOptions.arrowOffset || 0,
                            globalOptions.distance || 0
                        )
                    );
                }
            }
        };

        return api;
    }
}
